import React, { useState } from 'react';
import LoginForm from './LoginForm';
import ProtectedPage from './App2';

const App = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

    const handleLoginSuccess = () => {
        setIsAuthenticated(true);
    };

    return (
        <div>
            {!isAuthenticated ? (
                <LoginForm onLoginSuccess={handleLoginSuccess} />
            ) : (
                <ProtectedPage />
            )}
        </div>
    );
};

export default App;
