import axios from 'axios';

// Základní URL pro API běžící na portu 5000
const API_URL = 'https://wds.go-cargo-go.com/api'; // Pokud běží backend na localhost:5000
//const API_URL = 'https://ocean-wds.mauriceward.com/api'; // Pokud běží backend na localhost:5000

//const API_URL = 'http://localhost:5000/api'; // Pokud běží backend na localhost:5000

//const token = localStorage.getItem('token');

axios.interceptors.request.use(
  (config) => {
      const token = localStorage.getItem('token');
      if (token) {
          config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
  },
  (error) => {
      return Promise.reject(error);
  }
);


export const fetchRecordsFromDatabase = async () => {

  try {
    const response = await axios.get(`${API_URL}/records`);
  //  console.log(response.data);

    if (response.status === 401) {
      const data = await response.json();
      if (data.message === 'Token expired') {
        localStorage.removeItem('token');
            }
    } else if (response.status === 403) {
      localStorage.removeItem('token');
      alert('Invalid token or unauthorized');
    } else {
      return response.data;
    }
  } catch (error) {
    localStorage.removeItem('token');
    console.error('Error fetching records:', error);
    return [];
  }
};

/*
export const handleSearchById = async (searchId,setSelectedRecord) => {
  if (!searchId) return;
  try {
      const response = await axios.get(`${API_URL}/records/${searchId}`);
      const record = response.data;
      setSelectedRecord(record); // Select record for display or edit
  } catch (error) {
      console.error('Error fetching record by ID:', error);
  }
};*/
export const handleSearchById = async (searchId) => {
  if (!searchId) return null; // Pokud není `searchId` zadané, vrátí `null`

  try {
    const response = await axios.get(`${API_URL}/records/${searchId}`);
    const record = response.data;
    //console.log(record)
    return record; // Vrať nalezený záznam
  } catch (error) {
    if (error.response && error.response.status === 404) {
      console.warn(`Record with ID ${searchId} not found.`);
      return []; // Vrátí `null`, pokud je vrácena chyba 404
    }
    console.error('Error fetching record by ID:', error);
    throw error; // Ostatní chyby se propagují dál
  }
};

export const handleSearchByOwner = async () => {

///api/records/owner_records
  try {
    const response = await axios.get(`${API_URL}/records/owner/records`);
    const record = response.data;
    //console.log(record)
    return record; // Vrať nalezený záznam
  } catch (error) {
    if (error.response && error.response.status === 404) {
      //console.warn(`Record with ID ${searchId} not found.`);
      return []; // Vrátí `null`, pokud je vrácena chyba 404
    }
    console.error('Error fetching record by ID:', error);
    throw error; // Ostatní chyby se propagují dál
  }
};

export const createNewRecord = async (recordData) => {
  try {
    const response = await axios.post(`${API_URL}/records`,recordData);
    return response.data;
  } catch (error) {
    console.error('Error creating new record:', error);
  }
};

export const closeRecord = async (lot) => {
  try {
   // console.log('Closing record with lot:', lot);
    const response = await axios.put(`${API_URL}/records/${lot}/close`);
    return response.data;
  } catch (error) {
    console.error('Error closing record:', error);
  }
};

export const updateRecord = async (lot, updatedData) => {
  try {
    const response = await axios.post(`${API_URL}/records/update/${lot}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating record:', error);
  }
};

export const patialupdateRecord = async (lot, updatedData) => {
  try {
    const response = await axios.post(`${API_URL}/records/partialupdate/${lot}`, updatedData);
    return response.data;
  } catch (error) {
    console.error('Error updating record:', error);
  }
};