import React, { useEffect, useState } from 'react';
import autoTable from 'jspdf-autotable'

import { Button, TextField ,Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import PrintIcon from '@mui/icons-material/Print';

import { createNewRecord, closeRecord,updateRecord,patialupdateRecord } from './api';
import jsPDF,  {  AcroFormTextField} from 'jspdf'
import './OpenSans-Regular-normal';

const RecordForm = ({ selectedRecord, setIsEditMode, setSelectedRecord, handleClose, setRevKey }) => {
  const [alias, setAlias] = useState('');
  const [fmsid, setFmsid] = useState('');
  const [name, setName] = useState('');
 // const [ammount, setAmmount] = useState('');
  const [ammount, setAmmount] = useState(selectedRecord?.ammount || '0');
  const [unit, setUnit] = useState(1); // defaultní hodnota pro "paleta"

  const [description, setDescription] = useState('');

  useEffect(() => {
    if (selectedRecord) {
      setAlias(selectedRecord.alias);
      setFmsid(selectedRecord.fmsid);
      setName(selectedRecord.name);
      setAmmount(selectedRecord.ammount);
      setDescription(selectedRecord.description || '');
      setUnit(selectedRecord.unit);
    }
  }, [selectedRecord]);

  const handleSave = async () => {

    const recordData = { alias, name, ammount, description, fmsid, unit };
    await createNewRecord(recordData);
    handleClose();
    setRevKey(prevKey => prevKey + 1);
  };

  const updateRecord2 = async () => {
      const updatedData = { alias, name, ammount, description, fmsid, unit };
      await updateRecord(selectedRecord.lot, updatedData);
      handleClose();
      setRevKey(prevKey => prevKey + 1);

  }
  const handleUnitChange = (e) => {
    setUnit(e.target.value);
  };

  const getAmmountText = (ammount, unit) => {
    const unitText = {
      1: ['paleta', 'palety', 'palet'],       // Paleta
      2: ['box', 'boxy', 'boxů'],             // Box
      3: ['kus', 'kusy', 'kusů'],             // Kus
      4: ['krabice', 'krabice', 'krabic'],    // Krabice
      5: ['lahvička', 'lahvičky', 'lahviček'] // Lahvička
    };
  
    if (!unitText[unit]) return ''; // Pokud jednotka neexistuje
  
    const [singular, few, many] = unitText[unit];
  
    if (ammount === 1) {
      return singular;
    } else if (ammount >= 2 && ammount <= 4) {
      return few;
    } else {
      return many;
    }
  };

  const handleCloseRecord = async () => {
    if (selectedRecord) {
      await closeRecord(selectedRecord.lot);
      handleClose();
      setRevKey(prevKey => prevKey + 1);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const formattedDate = date.toLocaleDateString('cs-CZ', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
  });

  // Formátování času (HH:mm)
  const formattedTime = date.toLocaleTimeString('cs-CZ', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,  // Pro 24hodinový formát
  });

  return `${formattedDate}   ${formattedTime}`;
   };

   const getPartAmmountText = (ammount,unit) => {
    if (ammount === 1) {
      return `${getAmmountText(ammount,unit)} byla vydána`;
    } else if (ammount >= 2 && ammount <= 4) {
      return `${getAmmountText(ammount,unit)} byly vydány`;
    } else {
      return `${getAmmountText(ammount,unit)} bylo vydáno`;
    }
  };


//vydejka
const generatePull = () => {

  const doc = new jsPDF({
    orientation: 'portrait', // Standardní orientace
    unit: 'mm',
    format: 'a4', // Rozměry stránky A4
  });
  
    const formattedDate = formatDate(new Date());
    const alias = selectedRecord?.alias || '';
    const name = selectedRecord?.name || '';
    const ammount = selectedRecord?.ammount || '0';
   // const description = selectedRecord?.description || '';
    const recordLot = selectedRecord?.lot || '-';

    doc.setFont('OpenSans-Regular', 'normal');
    doc.setProperties({
    title: `Výdejka pro ${recordLot}`,
    author: 'WDS'
});

    doc.setFontSize(50);
    doc.text(`Výdejka pro ${recordLot}`,110,20, {align: 'center'});
    doc.setFontSize(35);

    doc.text(15, 50, `Datum: ${formattedDate}`);
    doc.autoTable({
      styles: {fontSize: 18},
      startY: 60,
      head: [['Lot', 'Alias', 'Jméno', 'Množství', 'Popis']],
      body: [[recordLot, alias, name, ammount, 'Shipment for MW Operation Department']],
    //  columnStyles: { 1: { cellWidth: 50 } },  // Define column width for signature
      theme: 'grid',  // Add grid lines
      //margin: { left: 20, right: 20 }
  });
    doc.text(15,140, `Počet ${getAmmountText(40,unit)} ${ammount}`);

    doc.autoTable({
     styles: {fontSize: 20},
     startY: 180,
     startX: 20,
     head: [['', '']],
      body: [
          ['SPZ', ''],
          ['Jméno', ''],
          ['Podpis', ''],
      ],
      //columnStyles: { 1: { cellWidth: 50 } },  // Define column width for signature
      columnStyles: { 0: { cellWidth: 50, halign: 'center', cellHigh: '30' } },  // Define column width for signature 
      theme: 'grid',  // Add grid lines
     // margin: { left: 20, right: 20 }
  });
  const lastY = doc.autoTable.previous.finalY; // Konec poslední tabulky
  const height = 10;

const fieldSPZ = new AcroFormTextField();
fieldSPZ.fieldName = "SPZ";
fieldSPZ.x = 70;
fieldSPZ.width = 120;
fieldSPZ.y = lastY - 34;
fieldSPZ.height = height;

  const fieldName = new AcroFormTextField();
  fieldName.fieldName = "Name";
  fieldName.x = 70;
  fieldName.width = 120;
  fieldName.y = lastY - 22;
  fieldName.height = height;
  
  doc.addField(fieldSPZ);

  doc.addField(fieldName);


  //doc.output('dataurlnewwindow');
  doc.save(`Výdejka_${recordLot}.pdf`)

 // doc.autoPrint();

  };

  //castecna vydejka
  const generatePartialPull = (quantity) => {

    const doc = new jsPDF({
      orientation: 'portrait', // Standardní orientace
      unit: 'mm',
      format: 'a4', // Rozměry stránky A4
    });
    
      const formattedDate = formatDate(new Date());
      const alias = selectedRecord?.alias || '';
      const name = selectedRecord?.name || '';
      const ammount = quantity;
     // const description = selectedRecord?.description || '';
      const recordLot = selectedRecord?.lot || '-';
  
      doc.setFont('OpenSans-Regular', 'normal');
      doc.setProperties({
      title: `Částečná výdejka pro ${recordLot}`,
      author: 'WDS'
  });
  
      doc.setFontSize(40);
      doc.text(`Částečná výdejka pro ${recordLot}`,110,20, {align: 'center'});
      doc.setFontSize(35);
  
      doc.text(15, 50, `Datum: ${formattedDate}`);
      doc.autoTable({
        styles: {fontSize: 18},
        headStyles: {
          fillColor: [255, 0, 0],     // Červené pozadí pro záhlaví
          textColor: [255, 255, 255], // Bílé písmo pro záhlaví
          fontStyle: 'bold',
        },
        startY: 60,
        head: [['Lot', 'Alias', 'Jméno', 'Množství', 'Popis']],
        body: [[recordLot, alias, name, ammount, 'Shipment for MW Operation Department']],
      //  columnStyles: { 1: { cellWidth: 50 } },  // Define column width for signature
        theme: 'grid',  // Add grid lines
        //margin: { left: 20, right: 20 }
    });
      doc.text(15,140, `Počet ${getAmmountText(40,unit)} k výdeji ${ammount}`);
  
      doc.autoTable({
       styles: {fontSize: 20},
       startY: 180,
       startX: 20,
       headStyles: {
        fillColor: [255, 0, 0],     // Červené pozadí pro záhlaví
        textColor: [255, 255, 255], // Bílé písmo pro záhlaví
        fontStyle: 'bold',
      },
       head: [['', '']],
        body: [
            ['SPZ', ''],
            ['Jméno', ''],
            ['Podpis', ''],
        ],
        //columnStyles: { 1: { cellWidth: 50 } },  // Define column width for signature
        columnStyles: { 0: { cellWidth: 50, halign: 'center', cellHigh: '30' } },  // Define column width for signature 
        theme: 'grid',  // Add grid lines
       // margin: { left: 20, right: 20 }
    });
    const lastY = doc.autoTable.previous.finalY; // Konec poslední tabulky
    const height = 10;
  
  const fieldSPZ = new AcroFormTextField();
  fieldSPZ.fieldName = "SPZ";
  fieldSPZ.x = 70;
  fieldSPZ.width = 120;
  fieldSPZ.y = lastY - 34;
  fieldSPZ.height = height;
  
    const fieldName = new AcroFormTextField();
    fieldName.fieldName = "Name";
    fieldName.x = 70;
    fieldName.width = 120;
    fieldName.y = lastY - 22;
    fieldName.height = height;
    
    doc.addField(fieldSPZ);
  
    doc.addField(fieldName);
  
  
    //doc.output('dataurlnewwindow');
    doc.save(`částečná_výdejka_${recordLot}.pdf`)
  
   // doc.autoPrint();
  
    };



//prijemka
  const generateReceipt = () => {
    const doc = new jsPDF({
      orientation: 'landscape', // Standardní orientace
      unit: 'mm',
      format: 'a4', // Rozměry stránky A4

    });

    // Common fieldNames for both types
    const alias = selectedRecord?.alias || '';
    const name = selectedRecord?.name || '';
    const ammount = selectedRecord?.ammount || '0';
    const recordLot = selectedRecord?.lot || '-';
    const formattedDate = formatDate(new Date());
    doc.setFont('OpenSans-Regular', 'normal');
    doc.setProperties({
    title: `Příjemka pro ${recordLot}`,
    author: 'WDS'
});

for (let i = 1; i <= ammount; i++) {
    doc.setFontSize(100);
    doc.text(`Příjemka`,148.5,30, {align: 'center'});
    doc.setFontSize(40);

    doc.text(`${formattedDate}`,148.5, 55, {align: 'center'});
    doc.text(`Jméno: ${name}`,148.5, 75, {align: 'center'});          
    doc.text(`Alias: ${alias}`,148.5, 95, {align: 'center'});           
 
    doc.setFontSize(100);
          
      doc.text(`Lot: ${recordLot}`, 148.5, 140, {align: 'center'});

               // doc.text(80, 180, `Pocet`);

                doc.text(`${i}. ${getAmmountText(1,unit)} z ${ammount}`, 148.5, 180, { align: 'center' });

    if (i < ammount) {
      doc.addPage();
    }
  }
    // Save the PDF with the dynamic filename
    //doc.save(`${copyType}_${recordLot}.pdf`);
   // doc.output('dataurlnewwindow');
   doc.save(`Příjemka_${recordLot}.pdf`)
  // doc.output('dataurlnewwindow');
  };

//částečný výdej
const handlePartialPull = async () => {
  const input = prompt("Zadejte počet pro částečný výdej:");
  const quantity = parseInt(input, 10);

  // Kontrola, zda je zadáno platné číslo a je menší než `ammount`
  if (!isNaN(quantity) && quantity > 0 && quantity <= ammount) {
    const newAmmount = ammount - quantity;
    setAmmount(newAmmount); // Aktualizuje ammount na novou hodnotu

    const currentDate = new Date();
    const formattedDate = currentDate.toLocaleDateString("cs-CZ");
    const newDescription = `${description} ${quantity} ${getPartAmmountText(quantity,unit)} dne ${formattedDate}`;

    const updatedData = { alias, name, newAmmount, newDescription, fmsid, unit};
    await patialupdateRecord(selectedRecord.lot, updatedData);
    handleClose();
    setRevKey(prevKey => prevKey + 1);

    await generatePartialPull(quantity); // Spuštění funkce pro vygenerování "Pull"
   // await updateRecord(newAmmount); // Aktualizace záznamu v databázi
  } else {
    alert("Zadejte platné číslo menší nebo rovné dostupnému množství.");
  }
};

  return (
    <div>        
      <form onSubmit={(e)=>e.preventDefault()}>

      <h2>{selectedRecord ? 'Upravit záznam' : 'Vytvořit nový'}</h2>
      {selectedRecord && (
        <TextField 
          label="Lot ID"
          value={selectedRecord.lot}
          fullWidth
          margin="2px"
          InputProps={{
            readOnly: true,
          }}
        />
      )}
      <TextField
        label="FMS ID"
        name="FMSID"
        value={fmsid}
        onChange={(e) => setFmsid(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField 
        label="Alias"
        name="alias"
        value={alias}
        onChange={(e) => setAlias(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField 
        label="Jméno"
        name="jmeno"
        value={name}
        onChange={(e) => setName(e.target.value)}
        fullWidth
        margin="normal"
      />

      <TextField 
        label="Množství"
        name="mnozstvi"
        value={ammount}
        onChange={(e) => {
          const value = e.target.value;
          if (value === '' || (Number(value) > 0 && /^\d+$/.test(value))) {
            setAmmount(value);
          }
        }}        
        fullWidth
        margin="normal"
        type='number'
      />      
      <FormControl fullWidth margin="normal">
          <InputLabel>Jednotka</InputLabel>
          <Select
            label="Jednotka"
            value={unit}
            onChange={handleUnitChange}
          >
            <MenuItem value={1}>Paleta</MenuItem>
            <MenuItem value={2}>Box</MenuItem>
            <MenuItem value={3}>Kus</MenuItem>
            <MenuItem value={4}>Krabice</MenuItem>
            <MenuItem value={5}>Lahvička</MenuItem>
          </Select>
        </FormControl>
      <TextField 
        name="popis"
        label="Popis (jen informativní pro mě)"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        fullWidth
        margin="normal"
      />
   
      <div><Button 
          variant="contained" 
          color="primary"
          onClick={() => generateReceipt()} 
        //  endIcon={<PrintIcon/>}
         // ref={receiptRef}
         // onClick={() => <ReceiptGenerator selectedRecord={selectedRecord} />}
          style={{ marginLeft: '10px' }}>
          Příjemka
        </Button>
        <Button 
          variant="contained" 
          color="secondary" 
        // endIcon={<PrintIcon/>}
          onClick={() => generatePull()} 
          style={{ marginLeft: '10px' }}>
          Výdejka
        </Button>
        {selectedRecord && (
          <Button variant="contained"
        //  endIcon={<PrintIcon/>}
          color="error"
          onClick={handlePartialPull}
          style={{ marginLeft: '10px' }}>
          Částečný výdej
          </Button>
        )}
        </div>
      <div style={{ marginTop: '20px' }}>
        <Button 
        variant="outlined" 
        color="primary" 
        onClick={selectedRecord ? updateRecord2 : handleSave}
        type='submit'
        endIcon={<SaveIcon/>}
        >
        {selectedRecord ? "Upravit" : "Uložit"}
  </Button>
        {selectedRecord && (
          <Button variant="outlined" color="secondary" onClick={handleCloseRecord}  endIcon={<DeleteIcon/>} style={{ marginLeft: '10px' }}>Smazat</Button>
        )}
        <Button variant="outlined" color="error" onClick={handleClose} endIcon={<CancelIcon/>} style={{ marginLeft: '10px' }}>Zrušit</Button>
       
      </div>
      </form>
    </div>
  );
};

export default RecordForm;
