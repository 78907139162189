import React, { useEffect, useState } from 'react';
import { fetchRecordsFromDatabase,handleSearchById, handleSearchByOwner /*createNewRecord, closeRecord, handleSearchById*/ } from './api';
import { Button, Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Dialog, DialogContent } from '@mui/material';
import RecordForm from './RecordForm';
//import { Modal } from '@mui/material';


import SearchIcon from '@mui/icons-material/Search';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AddIcon from '@mui/icons-material/Add';

//import axios from 'axios';
//import { RefreshContext } from './RefreshContext'; 


const App = () => {
  const [records, setRecords] = useState([]);
  const [searchLot, setSearchLot] = useState('');
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [revKey, setRevKey] = useState(0);
  const [searchResult, setSearchResult] = useState(null); 



  const handleOpenModal = (record = null) => {
    setSelectedRecord(record);
    setIsEditMode(!!record);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRecord(null);
    setIsEditMode(false);
  };


  const fetchData = async () => {

    const data = await fetchRecordsFromDatabase(); 
   // setRefreshKey(oldKey => oldKey + 1);
    setRecords(data);
  };

  useEffect(() => {
    fetchData();
    console.log(revKey);
  }, [revKey]);


  const getAmmountText = (ammount, unit) => {
    const unitText = {
      1: ['paleta', 'palety', 'palet'],       // Paleta
      2: ['box', 'boxy', 'boxů'],             // Box
      3: ['kus', 'kusy', 'kusů'],             // Kus
      4: ['krabice', 'krabice', 'krabic'],    // Krabice
      5: ['lahvička', 'lahvičky', 'lahviček'] // Lahvička
    };
  
    if (!unitText[unit]) return ''; // Pokud jednotka neexistuje
  
    const [singular, few, many] = unitText[unit];
  
    if (ammount === 1) {
      return singular;
    } else if (ammount >= 2 && ammount <= 4) {
      return few;
    } else {
      return many;
    }
  };

const handleSearch = async () => {
  //await handleSearchByLot(searchLot, setSelectedRecord); // Ověříme, že searchId je string nebo číslo
  const result =   await handleSearchById(searchLot);
  console.log(result);
  //const result = records.find(record => record.lot === searchLot); // Najdi záznam podle ID
  //setSearchResult(result); // Nastav ho jako výsledek
  setRecords(result);

};

const handleSearchOwner = async () => {
  //await handleSearchByLot(searchLot, setSelectedRecord); // Ověříme, že searchId je string nebo číslo
  const result =   await handleSearchByOwner();
  console.log(result);
  //const result = records.find(record => record.lot === searchLot); // Najdi záznam podle ID
//  setSearchResult(result); // Nastav ho jako výsledek

setRecords(result);
};

  return (
    <Container>
      <h1>Records Overview</h1>
      <div style={{justifyContent:'left', display:'flex'}}>
      <input
        type="text"
        value={searchLot}
        onChange={(e) => setSearchLot(e.target.value)}
        placeholder='Lot ID'
        style={{margin: '5px'}}
      /> 
      <Button variant="contained" style={{margin: '5px'}} onClick={handleSearch} endIcon={<SearchIcon/>} color="primary">Hledat</Button>
  
         <Button  variant="contained" color="primary" style={{margin: '5px'}} onClick={handleSearchOwner} endIcon={<PersonSearchIcon/>}> Moje zásilky</Button>
         <Button  variant="contained" color="primary" style={{margin: '5px'}} onClick={() => handleOpenModal()} endIcon={<AddIcon/>}> Vytvořit nový</Button>
          </div>

      <TableContainer component={Paper} style={{ marginTop: '20px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Lot ID</TableCell>
              <TableCell>FMS ID</TableCell>
              <TableCell>Datum</TableCell>
              <TableCell>Alias</TableCell>
              <TableCell>Jméno</TableCell>
              <TableCell>Množství</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {Array.isArray(searchResult) ? (
          searchResult.map((searchResult) => (
    <TableRow key={searchResult.lot} onClick={() => handleOpenModal(searchResult)}  sx={{ cursor: 'pointer', bgcolor : searchResult.active === false ? "error.main":"" }}>
      <TableCell>{searchResult.lot}</TableCell>
      <TableCell>{searchResult.fmsid}</TableCell>
      <TableCell>{new Date(searchResult.date).toLocaleDateString()}</TableCell>
      <TableCell>{searchResult.alias}</TableCell>
      <TableCell>{searchResult.name}</TableCell>
      <TableCell>{searchResult.ammount} {getAmmountText(searchResult.ammount, searchResult.unit)}</TableCell>
    </TableRow>))
  ) : (
    Array.isArray(records) && records.length > 0 ? (
      records.map((record) => (
        <TableRow key={record.lot} onClick={() => handleOpenModal(record)} sx={{ cursor: 'pointer', bgcolor : record.active === false ? "error.main":"" }}>
          <TableCell>{record.lot}</TableCell>
          <TableCell>{record.fmsid}</TableCell>
          <TableCell>{new Date(record.date).toLocaleDateString()}</TableCell>
          <TableCell>{record.alias}</TableCell>
          <TableCell>{record.name}</TableCell>
          <TableCell>{record.ammount} {getAmmountText(record.ammount, record.unit)}</TableCell>
        </TableRow>
      ))
    ) : (
      <TableRow>
        <TableCell colSpan={6} style={{ textAlign: 'center' }}>
          No records available- try hit F5 for refresh
        </TableCell>
      </TableRow>
    )
  )}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={openModal} onClose={handleCloseModal}>
        <DialogContent >
          <RecordForm 
            selectedRecord={selectedRecord}
            setIsEditMode={setIsEditMode}
            setSelectedRecord={setSelectedRecord}
            handleClose={handleCloseModal}
            setRevKey={setRevKey}

          />
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default App;
